import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';      
import 'bootstrap/dist/css/bootstrap.min.css';
import React,{useState, useEffect, useRef} from 'react'; 
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import './Shortner.css'
import { Toast } from 'primereact/toast';
import axios from '../../Utils/axios'
import { submitUrl } from "../../Utils/Urls";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import logo from '../../logo.png';

export default function Shortner() {
    const toast = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [url, setUrl] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [firstSource, setFirstSource] = useState(null);
    const [firstMedium, setFirstMedium] = useState(null);
    const [firstCampaign, setFirstCampaign] = useState(null);
    const [firstContent, setFirstContent] = useState(null);
    const [fullUrl, setFullUrl] = useState('');
    const [firstNameError, setFirstNameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [firstSourceError, setFirstSourceError] = useState(false);
    const [firstMediumError, setFirstMediumError] = useState(false);
    const [firstCampaignError, setFirstCampaignError] = useState(false);
    const [firstContentError, setFirstContentError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [urlError, setUrlError] = useState(false);
    const [error, setError] = useState(false);
    const [shortUrlError, setShortUrlError] = useState(false);
    const [isMounted, setIsMounted] = useState(false)

    
    const load = ()=> {
        console.log('submitting')
        console.log(fullUrl)
        updateGeneratedUrl();
        handleErrors();
        submitUtm();   
    };


    useEffect(() => {
        updateGeneratedUrl();
      }, [url, firstName, phone, firstSource, firstMedium, firstContent, firstCampaign]);

    const handleErrors = () => {
        
        if (isEmpty(url) || !isValidUrl(url)) {
            setUrlError(true);
            setError(true)
        }
        else{
            setUrlError(false);
            setError(false)
        }
        if (isEmpty(firstName)) {
            setFirstNameError(true);
            setError(true)
        }
        else{
            setFirstNameError(false);
            setError(false)
        }
        if (isphone(phone)) {
            setPhoneError(true);
            setError(true)
        }
        else{
            setPhoneError(false);
            setError(false)
        }
        // if (isEmpty(firstSource)) {
        //     setFirstSourceError(true);
        //     setError(true)
        // }
        // else{
        //     setFirstSourceError(false);
        //     setError(false)
        // }
        // if (isEmpty(firstMedium)) {
        //     setFirstMediumError(true);
        //     setError(true)
        // }
        // else{
        //     setFirstMediumError(false);
        //     setError(false)
        // }
        // if (isEmpty(firstCampaign)) {
        //     setFirstCampaignError(true);
        //     setError(true)
        // }
        // else{
        //     setFirstCampaignError(false);
        //     setError(false)
        // }
        // if (isEmpty(firstContent)) {
        //     setFirstContentError(true);
        //     setError(true)
        // }
        // else{
        //     setFirstContentError(false);
        //     setError(false)
        // }
    }

    const submitUtm = () => {
        if (!error){
            console.log({error})
            setLoading(false);
            const data = {
                long_url: fullUrl,
                base_url: url,
                first_name: firstName,
                phone: phone?.toString() ?? '',
                first_source: firstSource,
                first_medium: firstMedium,
                first_campaign: firstCampaign,
                first_content: firstContent
            }

            axios.post(submitUrl, data,{
                headers: {'Content-Type': 'application/json'},
            })
            .then((response)=>{
                if(response.status === 201){
                    console.log(response.data)
                    setLoading(false);
                    setIsMounted(!isMounted)
                    toast.current.show({severity:'success', summary: 'Success', detail:'Success', life: 1500});
                    // Delay the window reload by 2 seconds
                    setTimeout(() => {
                        // window.location.reload();
                        handleStatRedirect()
                    }, 1000)
                }
                else if(response.status === 207){
                    console.log(response.data)
                    setLoading(false);
                    setIsMounted(!isMounted)
                    setPhoneError(true)
                    setError(true)
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to shorten the Url', life: 3000 });
                }
                else{
                    console.log(response.data)
                    setLoading(false);
                    setIsMounted(!isMounted)
                    setUrlError(true)
                    setError(true)
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to shorten the Url', life: 3000 });
                }
            })
            .catch((error)=>{
                setLoading(false);
                setIsMounted(!isMounted)
                toast.current.show({severity:'error', summary: 'Error', detail:'Failed to shorten the Url', life: 3000});
            })     
            
        }   
    }


    function isEmpty(value) {
        return value === null || value === undefined || value === ''|| value.toString().trim() === '';
    }

    function isphone(value) {
        return value === null || value === undefined || value === ''|| value.toString().trim() === '' || value.toString().length !== 10;
    }

    const isValidUrl = (string) => {
        try {
            const url = new URL(string.includes('://') ? string : `http://${string}`);
            return true;
        } catch (_) {
            return false;
        }
    };

    const updateGeneratedUrl = () => {
        if (url != null || firstName!= null || phone != null || firstSource != null || firstMedium != null || firstContent != null || firstCampaign != null){
            try{
                const queryParams = new URLSearchParams();
            
                if (firstSource) queryParams.append('utm_source', firstSource);
                if (firstCampaign) queryParams.append('utm_campaign', firstCampaign);
                if (firstMedium) queryParams.append('utm_medium', firstMedium);
                if (firstContent) queryParams.append('utm_content', firstContent);

                const generated = new URL(url);
                generated.search = queryParams.toString();
                setFullUrl(generated.toString());
            }
            catch (err){
                setFullUrl('');
                setError(true)
                setUrlError(true)
            }
        }
    
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(fullUrl)
          .then(() => {
            toast.current.show({ severity: 'info', summary: 'Copied', detail: 'URL copied to the clipboard' });
          })
          .catch(err => {
            console.error('Failed to copy URL to clipboard', err);
          });
      };

    const handleStatRedirect = () => {
        navigate('/url_stats');
    }


    return (
        <div className="shortner-form-container">
            <Toast ref={toast} />
            <div className="shortner-form">
                <div className="form-header mb-3">
                    {/* <h3>UTM Builder</h3> */}
                    <img src='https://pfscores.com/static/media/header-logo.bdf339dde3491218c795f420843e9387.svg' alt="Logo" height={70} />
                    <Button className="stats-button" label="Stats" onClick={handleStatRedirect} />
                    
                </div>
                <div className={`card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3 border-top margin-bottom`}>
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0 mt-3">URL *</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className={`input-text mt-3 ${urlError ? 'p-invalid' : ''}`} placeholder="URL" value={url} onChange={(e) => setUrl(e.target.value)}/>
                        </div>

                        <>
                            <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            </div>
                            <div className="col-8 col-md-9 col-lg-11" style={{padding:'0px'}}>
                                <p className={`input-text-error m-0 mt-3 ${urlError ? '' : 'input-text-error-none'}`}>Please enter a valid URL</p>
                            </div>
                        </>

                    </div>
                </div>
                
                <div className={`card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3 ${firstNameError ? '' : 'margin-bottom'}`}>
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">First Name *</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={firstName} placeholder="First Name" onChange={(e) => setFirstName(e.target.value)}/>
                        </div>

                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{padding:'0px'}}>
                            <p className={`input-text-error m-0 mt-3 ${firstNameError ? '' : 'input-text-error-none'}`}>Please enter the First Name</p>
                        </div>
                    </div>
                </div>
                <div className={`card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3 ${phoneError ? '' : 'margin-bottom'}`}>
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">Phone *</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            {/* <InputText className="input-text" value={phone} placeholder="Phone: (url link nofitication)" onChange={(e) => setPhone(e.target.value)}/> */}
                            <InputNumber className="input-text" value={phone} placeholder="Phone: (url link nofitication)" onValueChange={(e) => setPhone(e.value)} useGrouping={false} maxLength={10}/>
                        </div>

                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{padding:'0px'}}>
                            <p className={`input-text-error m-0 mt-3 ${phoneError ? '' : 'input-text-error-none'}`}>Please enter a valid Phone</p>
                        </div>
                    </div>       
                </div>
                <div className={`card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3 ${firstSourceError ? '' : 'margin-bottom'}`}>
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">UTM Source</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={firstSource} placeholder="ig" onChange={(e) => setFirstSource(e.target.value)}/>
                        </div>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{padding:'0px'}}>
                            <p className={`input-text-error m-0 mt-3 ${firstSourceError ? '' : 'input-text-error-none'}`}>Please enter the First Source</p>
                        </div>
                    </div>                 
                </div>
                <div className={`card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3 ${firstMediumError ? '' : 'margin-bottom'}`}>
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">UTM Medium</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={firstMedium} placeholder="paid" onChange={(e) => setFirstMedium(e.target.value)}/>
                        </div>

                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{padding:'0px'}}>
                            <p className={`input-text-error m-0 mt-3 ${firstMediumError ? '' : 'input-text-error-none'}`}>Please enter the First Medium</p>
                        </div>
                    </div>
                </div>
                <div className={`card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3 ${firstCampaignError ? '' : 'margin-bottom'}`}>
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">UTM Campaign</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={firstCampaign} placeholder="John" onChange={(e) => setFirstCampaign(e.target.value)}/>
                        </div>

                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{padding:'0px'}}>
                            <p className={`input-text-error m-0 mt-3 ${firstCampaignError ? '' : 'input-text-error-none'}`}>Please enter the First Campaign</p>
                        </div>
                    </div>   
                </div>
                <div className={`card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3 ${firstContentError ? '' : 'margin-bottom'}`}>
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">UTM Content</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={firstContent} placeholder="reel" onChange={(e) => setFirstContent(e.target.value)}/>
                        </div>

                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{padding:'0px'}}>
                            <p className={`input-text-error m-0 mt-3 ${firstContentError ? '' : 'input-text-error-none'}`}>Please enter the First Content</p>
                        </div>
                    </div>              
                </div>
                
                <div className="form-footer card d-flex flex-row align-items-center justify-content-center border-0 gap-3">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-7 col-md-8 col-lg-10" style={{padding:'1rem'}}>
                        <span className="p-input-icon-right full-width">
                            <i className="pi pi-copy copy-icon" style={{ fontSize: '1.5rem' }}  onClick={copyToClipboard}/>
                            <InputText className="full-url-text" value={fullUrl}/>
                        </span>
                        </div>
                        
                        <div className="col-5 col-md-4 col-lg-2" style={{padding:'1rem'}}>
                            <Button className="shorten-button" label="Generate Short URL" onClick={load} />
                        </div>
                    </div>    
                </div>
            
            </div>
        </div>
    )
}





